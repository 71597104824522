<template>
  <v-app>
    <v-app-bar
      max-width="100%"
      max-height="8%"
      dark
      flat
    >
      <v-content>
        <Header/>
      </v-content>
    </v-app-bar>

    <v-main>
      <router-view path="$router.key"></router-view>
    </v-main>
      <Footer/>
  </v-app>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer';

export default {
  name: 'App',

  components: {
    Header,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>
<style scoped>
</style>
