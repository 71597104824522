<template>
  <div id="main">
    <div class="carrousel">
      <v-card class="mt-1 elevation-23">
      <v-carousel height="100%">
        <v-carousel-item
          reverse-transition="fade-transition"
          transition="fade-transition"
          :src='require("../assets/images/banner2.png")'
        ><v-img></v-img></v-carousel-item>
        </v-carousel>
      </v-card>
    </div>

      <v-row
        no-gutters
        style="height: auto;"
        class="home"
      >
        <v-col cols="6" sm="3" md="3" class="icons">
          <img class="afd" src="../assets/icons/1.svg">
          <p class="d-flex justify-center align-center text-center">Transporte de Cargas</p>
        </v-col>
        <v-col cols="6" sm="3" md="3" class="icons">
          <img class="afd" src="../assets/icons/2.svg">
          <p class="d-flex justify-center align-center text-center">Mudanças Residenciais e Comerciais</p>
        </v-col>
        <v-col cols="6" sm="3" md="3" class="icons">
          <img class="afd" src="../assets/icons/bag.svg">
          <p class="d-flex justify-center align-center text-center">Serviços de Embalagem</p>
        </v-col>
        <v-col cols="6" sm="3" md="3" class="icons">
          <img class="afd" src="../assets/icons/3.svg">
          <p class="d-flex justify-center align-center text-center">Montagem e Desmontagem de Móveis</p>
        </v-col>
      </v-row>

    <v-row
        no-gutters
        style="height: 150px;"
      >
        <v-col class="d-flex justify-center align-center" style="margin-bottom: 5%;">
          <div class="my-2">
            <router-link to="/orcamento">
              <v-btn
                color="red"
                dark
                large
              >
                Preencha seu orçamento!
              </v-btn>
            </router-link>
          </div>
        </v-col>
    </v-row>
      <v-row
        align="center"
        justify="center"
        no-gutters
        class="middle"
      >
        <v-col class="align-content-center" cols="12" sm="6" md="6">
          <p class="text-center teste font-weight-black">
           Cuidado
         </p>
          <v-card
            class="mx-auto"
            max-width="600"
            outlined
            color="transparent"
          >
            <p class="text-center teste2 font-weight-bold">
              Disponibilizamos caixas para guarda de pequenos materiais, caixas para transportes de TV, araras para guarda de roupas em cabide
            </p>
          </v-card>
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <img class="mr-3 image-template" :src='require("../assets/images/couple.jpg")'/>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
        no-gutters
        style="background-color: #000000; color: white;"
        class="middle"
      >
        <v-col class="align-content-center" cols="12" sm="6" md="6">
            <p class="text-center teste font-weight-black">
            Agilidade, Segurança e Pontualidade
            </p>
            <v-card
              class="mx-auto"
              max-width="600"
              outlined
              color="transparent"
            >
              <p class="text-center teste3 font-weight-bold">
                Possuímos uma equipe treinada e disposta a realizar seu frete/mudança com agilidade e presteza. <br>
                Sua carga é montada de forma extremamente segura, de forma que não ocorra movimentação dentro do caminhão na hora do transporte.<br>
                Primamos pela pontualidade é uma das formas de demonstrarmos nosso respeito a vocês clientes.<br>
              </p>
            </v-card>
          </v-col>
        <v-col class="d-flex justify-center align-center">
          <img class="mr-3 image-template" :src='require("../assets/images/couple2.jpg")'/>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
        no-gutters
        class="middle"
      >
        <v-col class="align-content-center" cols="12" sm="6" md="6">
          <p class="text-center teste font-weight-black">
           Soluções Inteligentes
         </p>
          <v-card
            class="mx-auto"
            max-width="600"
            outlined
            color="transparent"
          >
            <p class="text-center teste2 font-weight-bold">
              Oferecemos serviços de montagem e desmontagem de móveis, fornecimento e serviços de embalagens, içamento, guada-móveis, instalações elétricas, limpeza de pré e pós mudança, bem como todo e qualquer serviço necessário para seu frete e mudança.
            </p>
          </v-card>
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <img class="mr-3 image-template" :src='require("../assets/images/worker.jpg")'/>
        </v-col>
      </v-row>

  </div>
</template>

<script>
  export default {
    data () {
      return {

      }
    }
  }
</script>
<style scoped>
#main {
  background-color: rgba(235, 235, 235, 0.623);
}
.home {
  font-size: 20px;
}
.image-template {
  width: 700px;
  height: 400px;
}
.teste {
  font-size: 34px;
}
.teste2 {
  font-size: 24px;
}
.teste3 {
  font-size: 24px;
  color: white;
}
.carrousel {
  margin-top: -4px;
  margin-bottom: 70px;
  max-width: 100%;
}
.afd {
  display: block;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
}
.icons {
  margin-bottom: 3%;
}
.middle {
  margin-bottom: 5%;
}
</style>
