<template>
<div>
  <h1 class="principletitle">ORÇAMENTO MUDANÇA</h1>
  <p class="subtitle">Preencha os dados abaixo para que possamos entender sobre sua mudança e como podemos lhe ajudar.</p>
   <v-form v-model="valid" @submit.prevent="onCreatePost">
    <h1 class="title">DADOS CADASTRAIS</h1>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="name"
            :rules="nameRules"
            label="Nome Completo"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="whatsapp"
            :rules="nameRules"
            label="Celular com WhatsApp"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
         <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="changetime"
            :rules="emailRules"
            label="Quando pretende se mudar?"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <h1 class="title">ORIGEM</h1>

    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="sourceLocation"
            :rules="nameRules"
            label="Endereço Completo"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="sourceCity"
            :rules="nameRules"
            label="Cidade"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="sourceState"
            :rules="emailRules"
            label="Estado"
            required
          ></v-text-field>
        </v-col>
         <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="sourceCep"
            :rules="numberRules"
            label="CEP"
            type="number"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="sourceRealtyType"
            :items="itensImovel"
            :error-messages="errors"
            label="Tipo imóvel"
            data-vv-name="select"
            required
          ></v-select>
        </v-col>
         <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="sourceFloor"
            :rules="emailRules"
            label="Se apartamento, qual andar?"
            type="number"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="sourceFootage"
            :rules="emailRules"
            label="Metragem imóvel (M²)"
            required
          ></v-text-field>
        </v-col>
         <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="sourceBedroom"
            :rules="emailRules"
            label="Nº de dormitórios"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="sourceElevator"
            :items="itensBoolean"
            :error-messages="errors"
            label="Possui elevador?"
            data-vv-name="select"
            required
          ></v-select>
        </v-col>
         <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="sourceNotElevator"
            :rules="emailRules"
            label="Algo não entra no elevador?"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <h1 class="title">DESTINO</h1>

    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="destinationLocation"
            :rules="nameRules"
            label="Endereço Completo"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="destinationCity"
            :rules="nameRules"
            label="Cidade"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="destinationState"
            :rules="emailRules"
            label="Estado"
            required
          ></v-text-field>
        </v-col>
         <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="destinationCep"
            :rules="emailRules"
            label="CEP"
            type="number"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="destinationRealtyType"
            :items="itensImovel"
            :error-messages="errors"
            label="Tipo imóvel"
            data-vv-name="select"
            required
          ></v-select>
        </v-col>
         <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="destinationFloor"
            :rules="emailRules"
            label="Se apartamento, qual andar?"
            type="number"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="destinationFootage"
            :rules="emailRules"
            label="Metragem imóvel (M²)"
            required
          ></v-text-field>
        </v-col>
         <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="destinationBedroom"
            :rules="emailRules"
            label="Nº de dormitórios"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="destinationElevator"
            :items="itensBoolean"
            :error-messages="errors"
            label="Possui elevador?"
            data-vv-name="select"
            required
          ></v-select>
        </v-col>
         <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="destinationNotElevator"
            :rules="emailRules"
            label="Algo não entra no elevador?"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <h1 class="title">SERVIÇOS ADICIONAIS</h1>

    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Montagem de móveis"
              color="error"
              value="Montagem de móveis"
              hide-details
            ></v-checkbox>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Lavagem de sofá"
              color="error"
              value="Lavagem de sofá"
              hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Limpeza pré mudança"
              color="error"
              value="Limpeza pré mudança"
              hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Desmontagem de móveis"
              color="error"
              value="Desmontagem de móveis"
              hide-details
            ></v-checkbox>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Lavagem de tapetes"
              color="error"
              value="Lavagem de tapetes"
              hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Limpeza pós mudança"
              color="error"
              value="Limpeza pós mudança"
              hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Embalagem"
              color="error"
              value="Embalagem"
              hide-details
            ></v-checkbox>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Instalação de cortinas"
              color="error"
              value="Instalação de cortinas"
              hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Desinfecção de ambientes"
              color="error"
              value="Desinfecção de ambientes"
              hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Desembalagem"
              color="error"
              value="Desembalagem"
              hide-details
            ></v-checkbox>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Instalação de espelhos"
              color="error"
              value="Instalação de espelhos"
              hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Dedetização de ambientes"
              color="error"
              value="Dedetização de ambientes"
              hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Transporte"
              color="error"
              value="Transporte"
              hide-details
            ></v-checkbox>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Instalação de quadros"
              color="error"
              value="Instalação de quadros"
              hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Personal organizer"
              color="error"
              value="Personal organizer"
              hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Içamento"
              color="error"
              value="Içamento"
              hide-details
            ></v-checkbox>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Instalação de eletrodoméstico"
              color="error"
              value="Instalação de eletrodoméstico"
              hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="additionalServices"
              label="Box privativo (self storage)"
              color="error"
              value="Box privativo (self storage)"
              hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-container>

    <v-container style="margin-bottom: 100px;">
      <h1 class="title">SUA MUDANÇA</h1>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="packThings"
            :items="itensTransportar"
            :error-messages="errors"
            label="Louças, miudezas e roupas, embalados e transportados"
            data-vv-name="select"
            required
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="box"
            :rules="nameRules"
            label="Quantidade estimada de caixas"
            type="number"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <div class="buttonsend">
      <v-row
          align-items="center"
          justify="space-around"
        >
        <vue-recaptcha sitekey="6LeNYaEgAAAAAMDnBzRyPFH_0Pmtj5NE4F7D-82n"></vue-recaptcha>
      </v-row>
    </div>
    <div class="buttonsend">
      <input type="text">
      <v-row
        align-items="center"
        justify="space-around"
        @click="onCreatePost"
      >
        <v-btn
        color="rgba(0, 255, 255)"
        >
        ENVIAR
        </v-btn>
      </v-row>
    </div>
  </v-form>
</div>
</template>

<script>
import axios from 'axios';
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  data () {
    return {
      itensTransportar: ['Via Alcarria', 'Via Cliente', 'Não haverá caixas para embalar ou transportar'],
      itensImovel: ['Apartamento', 'Casa (condomínio)', 'Casa (rua)', 'Depósito', 'Comercial', 'Outro'],
      itensBoolean: ['Sim', 'Não'],
      ex4: ['error'],
      additionalServices: [],
      name: '',
      email: '',
      whatsapp: '',
      changetime: '',
      sourceLocation: '',
      sourceCity: '',
      sourceState: '',
      sourceCep: '',
      sourceRealtyType: '',
      sourceFloor: '',
      sourceFootage: '',
      sourceBedroom: '',
      sourceElevator: '',
      sourceNotElevator: '',
      destinationLocation: '',
      destinationCity: '',
      destinationState: '',
      destinationCep: '',
      destinationRealtyType: '',
      destinationFloor: '',
      destinationFootage: '',
      destinationBedroom: '',
      destinationElevator: '',
      destinationNotElevator: '',
      packThings: '',
      box: '',
    };
  },
  components: { VueRecaptcha },
  methods: {
    onCreatePost(){
      axios.post(
        'https://formspree.io/f/xnqwygkb',
        {
          Nome_Completo: this.name,
          Email: this.email,
          Whatsapp: this.whatsapp,
          Quando_pretende_se_mudar: this.changetime,
          Endereco_Completo_Origem: this.sourceLocation,
          Cidade_Origem: this.sourceCity,
          Estado_Origem: this.sourceState,
          CEP_Origem: this.sourceCep,
          Tipo_de_Imovel_Origem: this.sourceRealtyType,
          Andar_do_Apartamento_Origem: this.sourceFloor,
          Metragem_Imóvel_Origem: this.sourceFootage,
          N_de_Dormitório_Origem: this.sourceBedroom,
          Possui_Elevador_Origem: this.sourceElevator,
          Algo_Nao_Entra_no_Elevador_Origem: this.sourceNotElevator,
          Endereco_Completo_Destino: this.destinationLocation,
          Cidade_Destino: this.destinationCity,
          Estado_Destino: this.destinationState,
          CEP_Destino: this.destinationCep,
          Tipo_de_Imovel_Destino: this.destinationRealtyType,
          Andar_do_Apartamento_Destino: this.destinationFloor,
          Metragem_Imóvel_Destino: this.destinationFootage,
          N_de_Dormitório_Destino: this.destinationBedroom,
          Possui_Elevador_Destino: this.destinationElevator,
          Algo_Nao_Entra_no_Elevador_Destino: this.destinationNotElevator,
          Servicos_Adicionais: this.additionalServices,
          Roupas_Miudezas_Embalados_Transportados: this.packThings,
          Caixas_Estimadas: this.box
        },
      ).then(response => {
        console.log(response);
      }).then(alert('Formulário Enviado com Sucesso!')).then(
        window.location.replace('https://alcarriatransportes.com.br'));
    }
  }
};
</script>
<style>
.title {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  color: aqua;
}
.principletitle {
  margin-top: 30px;
  text-align: center;
  font-size: 30px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.subtitle{
  margin-top: -20px;
  text-align: center;
}
.buttonsend {
  margin-bottom: 100px;
  margin-top: -80px;
}
</style>