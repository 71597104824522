<template>
      <v-toolbar flat max-width="100%">
        <router-link to="/">
        <img class="" :src='require("../assets/images/logobranco.png")'/>
        </router-link>

        <v-spacer></v-spacer>

        <router-link to="/">
          <v-btn text>
            HOME
          </v-btn>
        </router-link>

        <router-link to="/orcamento">
          <v-btn text>
            Orçamento
          </v-btn>
        </router-link>

        <router-link to="/sobre">
          <v-btn text>
            Sobre
          </v-btn>
        </router-link>

        <v-spacer></v-spacer>

        <v-btn
            class="mx-3 light--text"
            icon
            href="https://www.facebook.com/fretesvictoralcarria/"
          >
            <v-icon size="24px">
                mdi-facebook
            </v-icon>
          </v-btn>
          <v-btn
            class="mx-3 light--text"
            icon
            href="http://api.whatsapp.com/send?1=pt_BR&phone=5544998229874"
          >
            <v-icon size="24px">
                mdi-whatsapp
            </v-icon>
          </v-btn>
          <v-btn
            class="mx-3 light--text"
            icon
            href="https://www.instagram.com/alcarriatransportes/"
          >
            <v-icon size="24px">
                mdi-instagram
            </v-icon>
          </v-btn>
      </v-toolbar>
</template>

<script>
  export default {
    name: 'Header',

    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-whatsapp',
        'mdi-instagram',
      ],
    }),
  }
</script>
