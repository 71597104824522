import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Orcamento from '@/views/Orcamento.vue'
import Sobre from '@/views/Sobre.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/orcamento',
    name: 'Orcamento',
    component: Orcamento
  },
  {
    path: '/sobre',
    name: 'Sobre',
    component: Sobre
  },
  { path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
