<template>
    <v-footer
      id="footer"
      dark
      padless
      flat
      max-width="100%"
    >
      <div class="black">
        <v-card-text>
          <v-btn
            class="mx-3 light--text"
            icon
            href="https://www.facebook.com/fretesvictoralcarria/"
          >
            <v-icon size="24px">
                mdi-facebook
            </v-icon>
          </v-btn>
          <v-btn
            class="mx-3 light--text"
            icon
            href="http://api.whatsapp.com/send?1=pt_BR&phone=5544998229874"
          >
            <v-icon size="24px">
                mdi-whatsapp
            </v-icon>
          </v-btn>
          <v-btn
            class="mx-3 light--text"
            icon
            href="https://www.instagram.com/alcarriatransportes/"
          >
            <v-icon size="24px">
                mdi-instagram
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="light--text pt-0 ">
          CNPJ: 31.730.751/0001-97
        </v-card-text>
        <v-card-text class="light--text pt-0">
          orcamentos@alcarriatransportes.com.br
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="light--text">
          {{ new Date().getFullYear() }} — <strong>Alcarria Transportes</strong>
        </v-card-text>
      </div>
    </v-footer>
</template>

<script>
  export default {
    name: 'Footer',

    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-whatsapp',
        'mdi-instagram',
      ],
    }),
  }
</script>
<style scoped>
#footer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
  text-align: center;
}
</style>
